export const SET_REORDER_CUSTOMER = "SET_REORDER_CUSTOMER";
export const SET_REORDER_CUSTOMER_SUCCESS = "SET_REORDER_CUSTOMER_SUCCESS";
export const SET_REORDER_CUSTOMER_FAILED = "SET_REORDER_CUSTOMER_FAILED";


export const SET_CUSTOMER_FORM = "SET_CUSTOMER_FORM";
export const SET_CUSTOMER_FORM_SUCCESS = "SET_CUSTOMER_FORM_SUCCESS";
export const SET_CUSTOMER_FORM_FAILED = "SET_CUSTOMER_FORM_FAILED";


export const CHANGE_PASSWORD_START = 'CHANGE_PASSWORD_START'
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE'

export const GET_USER_START = 'GET_USER_START'
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS'
export const GET_USER_FAILURE = 'GET_USER_FAILURE'

export const UPDATE_USER_START = 'UPDATE_USER_START'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE'

export const GET_CUSTOMER_HISTORY_REQUEST = "GET_CUSTOMER_HISTORY_REQUEST";
export const GET_CUSTOMER_HISTORY_SUCCESS = "GET_CUSTOMER_HISTORY_SUCCESS";
export const GET_CUSTOMER_HISTORY_FAILED = "GET_CUSTOMER_HISTORY_FAILED";

export const GET_CUSTOMER_PAYMENT_HISTORY_REQUEST = "GET_CUSTOMER_PAYMENT_HISTORY_REQUEST";
export const GET_CUSTOMER_PAYMENT_HISTORY_SUCCESS = "GET_CUSTOMER_PAYMENT_HISTORY_SUCCESS";
export const GET_CUSTOMER_PAYMENT_HISTORY_FAILED = "GET_CUSTOMER_PAYMENT_HISTORY_FAILED";


export const GET_STAFF_REQUEST = "GET_STAFF_REQUEST";
export const GET_STAFF_SUCCESS = "GET_STAFF_SUCCESS";
export const GET_STAFF_FAILED = "GET_STAFF_FAILED";

export const DELETE_STAFF_REQUEST = "DELETE_STAFF_REQUEST";
export const DELETE_STAFF_SUCCESS = "DELETE_STAFF_SUCCESS";
export const DELETE_STAFF_FAILED = "DELETE_STAFF_FAILED";

export const INVITE_STAFF_REQUEST = "INVITE_STAFF_REQUEST";
export const INVITE_STAFF_SUCCESS = "INVITE_STAFF_SUCCESS";
export const INVITE_STAFF_FAILED = "INVITE_STAFF_FAILED";


export const AGAIN_PREREQUEST_REQUEST = "AGAIN_PREREQUEST_REQUEST";
export const AGAIN_PREREQUEST_SUCCESS = "AGAIN_PREREQUEST_SUCCESS";
export const AGAIN_PREREQUEST_FAILED = "AGAIN_PREREQUEST_FAILED";

