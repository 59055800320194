import firebase from 'firebase';
import { firebaseConfig } from 'shared/constant';

// Initialize Firebase
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
}
const analytics = firebase.analytics()

const addEvent = (event, action, obj) => {
    analytics.logEvent(event, {
        'timestamp': Date.now(),
        'action': action,
        'ip': localStorage.getItem('ip_address'),
        'action_by_role': localStorage.getItem('role'),
        'action_by_name': localStorage.getItem('dealer_name'),
        'action_by_email': localStorage.getItem('email'),
        'action_by_id': localStorage.getItem('email'),
        'app_id': "web:acf213ff9ea01e07fa8279",
        "app_info": {
            id: "web:acf213ff9ea01e07fa8279",
            version: '1.0'
        },  
        ...obj
    });
}

export {
    firebase,
    addEvent
}